module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PQBVXT9","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"257678602195198"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alugue-imoveis-em-peruibe","short_name":"Paulinho-imoveis","start_url":"/","background_color":"#1b6dec","theme_color":"#1b6dec","display":"minimal-ui","icon":"src/images/paulinho-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2cb08b813c6daf10366dba17db8c0426"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
